import React, {Component} from 'react';
import Layout from "../components/Layout/Layout";
import LayoutWithMenu from "../components/Layout/LayoutWithMenu";
import {graphql} from "gatsby";
import ProjectList from "../components/ProjectList/ProjectList";
import styles from './page.module.sass'
import PeopleList from "../components/PeopleList/PeopleList";
import {Helmet} from "react-helmet";

export default class withWho extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        // this.color = this.props.data.colors.edges[Math.floor(Math.random() * this.props.data.colors.edges.length)].node.frontmatter.color
        this.peoples = this.props.data.peoples.edges

    }

    render() {
        this.color = this.props.data.colors.edges[Math.floor(Math.random() * this.props.data.colors.edges.length)].node.frontmatter.color
        return (
            <Layout isIndex={true} background={this.color}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>↪ cocktail — atelier ∆ Bayonne</title>
                    <meta name="description" content="Lucie Lafitte & Chloé Serieys ⇆ Atelier multidisciplinaire / design graphique, fabrication, pédagogie, édition, coordination." />
                </Helmet>
                <LayoutWithMenu path={this.props.location.pathname}>
                    <div className={styles.withWhoContainer}>
                        <div className={styles.introTextContainer}>
                            <p className={styles.introText}>Nous construisons des projets et travaillons avec
                                institutions
                                publiques, associations, artistes, structures culturelles, privés, inconnus, amis,
                                proches,
                                entreprises, etc etc etc etc</p>
                        </div>
                        <PeopleList data={this.peoples}/>
                    </div>
                </LayoutWithMenu>
            </Layout>
        );
    }
}

export const pageQuery = graphql`
  query WithWhoQuery {
    colors: allMarkdownRemark(filter: {frontmatter: {type: {eq: "color"}}}) {
        edges {
          node {
            frontmatter {
              color
            }
          }
        }
    }
    peoples: allMarkdownRemark(filter: {frontmatter: {type: {eq: "people"}}}, sort: {fields: frontmatter___name, order: DESC}) {
        edges {
          node {
            frontmatter {
              name
              link
            }
          }
        }
    }
  }`