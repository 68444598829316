import React, {Component} from 'react';
import styles from './PeopleList.module.sass'

export default class PeopleList extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.peopleItem = this.props.data.map((el) =>
        el.node.frontmatter.link ?
            <a className={styles.people} href={el.node.frontmatter.link} target="_blank">¨ {el.node.frontmatter.name}</a>
            :
            <span className={styles.people}>¨ {el.node.frontmatter.name}</span>
        );

    }

    render() {
        return (
            <div className={styles.peoplesContainer}>
                {this.peopleItem}
            </div>
        );
    }
}